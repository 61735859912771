<template>
  <div>
    <Header />

    <div class="InAppNetwork">
      <div class="InAppNetworkStats">
        <div class="InAppNetworkStats-List">
          <div class="InAppNetworkStats-Item">
            <div class="InAppNetworkStats-ItemIn">
              <div class="InAppNetworkStats-ItemLabel">Downloads</div>
              <div class="InAppNetworkStats-ItemValue">
                <strong>30</strong>MLN
              </div>
            </div>
          </div>
          <div class="InAppNetworkStats-Item">
            <div class="InAppNetworkStats-ItemIn">
              <div class="InAppNetworkStats-ItemLabel">Impressions</div>
              <div class="InAppNetworkStats-ItemValue">
                <strong>1</strong>BILLION
              </div>
            </div>
          </div>
          <div class="InAppNetworkStats-Item">
            <div class="InAppNetworkStats-ItemIn">
              <div class="InAppNetworkStats-ItemLabel">Clicks</div>
              <div class="InAppNetworkStats-ItemValue">
                <strong>7.5</strong>MLN
              </div>
            </div>
          </div>
        </div>
        <div class="InAppNetworkStats-Icon"></div>
      </div>

      <div class="InAppNetworkAbout">
        <div class="InAppNetworkAbout-In">
          <div class="InAppNetworkAbout-Text">
            <div class="InAppNetworkAbout-TextText">
              <p class="InAppNetworkAbout-TextFirst">The WakeApp Network works with the&nbsp;largest advertising platforms and game publishers. We've generated over 1&nbsp;billion impressions and attracted over 2&nbsp;million active users</p>
              <p class="InAppNetworkAbout-TextSecond">We filter traffic using a modern anti-fraud system: <strong class="InAppNetworkAbout-TextMarked">Protect 360</strong></p>
            </div>

            <ul class="InAppNetworkAbout-Benefits">
              <li class="InAppNetworkAbout-BenefitsItem">
                <span class="InAppNetworkAbout-BenefitsItemBtn">
                  Indicators: CTIT, CR, uninstall&nbsp;rates and&nbsp;others
                </span>
              </li>
              <li class="InAppNetworkAbout-BenefitsItem">
                <span class="InAppNetworkAbout-BenefitsItemBtn">
                  Special traffic distribution algorithm — DSP
                </span>
              </li>
              <li class="InAppNetworkAbout-BenefitsItem">
                <span class="InAppNetworkAbout-BenefitsItemBtn">
                  High LTV of our traffic 
                </span>
              </li>
            </ul>
          </div>
          <div class="InAppNetworkAbout-Picture">
            <img src="@/assets/images/in-app-network/phone.png" width="575" alt="674">
          </div>
        </div>
      </div>

      <FormSection />
    </div>  
    
    <Footer/>
  </div>
</template>
<script>
import Header from "@/components/Header";
import FormSection from "@/components/FormSection";
import Footer from "@/components/Footer";

export default {
  name: "Contacts",
  components: {
    FormSection,
    Header,
    Footer
  },
  metaInfo: {
    title: 'In-App Network — WakeApp',
    meta: [{
      vmid: 'description',
      name: 'description',
      content:"The WakeApp Network works with the largest advertising platforms and game publishers. We've generated over 1 billion impressions and attracted over 2 million active users",
    }],
  }
}
</script>

<style lang="scss">
.InAppNetwork {
  position: relative;
  z-index: 1;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-image: url('~@/assets/images/in-app-network/logos.png');
  background-position: 50% 0;
  background-repeat: no-repeat;
  transition: opacity 0.5s ease-in-out;
  
  @include s-m {
    background-position: 50% 9.29vw;
    background-size: 80% auto;
    margin-top: -13.57vw;
  }

  @include s-l {
    background-size: auto auto;
  }

  @include s-xxl {
    max-width: 1400px;
    margin-top: -190px;
    background-position: 50% 130px;
  }

  &:not(&--ready) {
    opacity: 0;
  }

  .FormSection {
    position: relative;
    z-index: 2;
    margin-top: -8.5vw;

    @include s-xxl {
      margin-top: -175px;
    }
  }  
}

.InAppNetworkStats {
  width: 300px;
  margin-right: auto;
  margin-left: auto;

  @include s-m {
    width: 49.29vw;
  }

  @include s-xxl {
    width: 690px;
  }

  &-List {
    position: relative;
    z-index: 1;

    @include s-m {
      display: flex;
      margin-bottom: 2.14vw;
    }

    @include s-xxl {
      margin-bottom: 30px;
    }

    &::before {
      @include s-m {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -1.79vw);
        width: 24.93vw;
        height: 7vw;
        background-image: url('~@/assets/images/in-app-network/connectors.svg');
        background-repeat: no-repeat;
        background-size: 100% auto;
      }

      @include s-xxl {
        width: 349px;
        height: 98px;
        transform: translate(-50%, -25px);
      }
    }
  }

  &-Item {
    margin-bottom: 15px;

    @include s-m {
      margin-bottom: 0;
      width: 33.333%;
      flex: 0 0 auto;
      padding-right: 0.36vw;
      padding-left: 0.36vw;
    }

    @include s-xxl {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  &-ItemIn {
    display: inline-block;
    border-radius: 11px;
    padding: 15px 20px;
    background-color: var(--color-bg-main1);
    color: var(--color-text-main);
    line-height: 1;
    font-family: 'Roboto', sans-serif;

    :nth-child(1) > & {
      margin-left: 12%;
    }

    :nth-child(3) > & {
      margin-left: 28%;
    }
    
    @include s-m {
      display: block;
      margin-left: 0 !important;
      border-radius: 1.21vw;
      padding: 1.07vw;
    }

    @include s-xxl {
      border-radius: 17px;
      padding: 15px;
    }
  }

  &-ItemLabel {
    font-size: 16px;
    margin-bottom: 0.36vw;

    @include s-m {
      font-size: 1.43vw;
    }

    @include s-xxl {
      margin-bottom: 5px;
      font-size: 20px;
    }
  }

  &-ItemValue {
    padding-left: 1.86vw;
    background-image: url('~@/assets/images/in-app-network/arrow-up.svg');
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: 1.43vw auto;
    font-size: 32px;
    text-transform: uppercase;
    
    @include s-m {
      font-size: 2.71vw;
    }

    @include s-xxl {
      padding-left: 26px;
      background-size: 20px auto;
      font-size: 38px;
    }
  }

  &-Icon {
    position: absolute;
    top: 25px;
    left: 48%;
    z-index: 2;
    display: block;
    width: 163px;
    height: 163px;
    margin-right: auto;
    margin-left: auto;
    background-image: url('~@/assets/images/in-app-network/wakeapp-icon.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;

    @include s-m {
      position: relative;
      top: 0;
      left: 0;
      width: 18.93vw;
      height: 18.86vw;
    }

    @include s-xxl {
      width: 265px;
      height: 264px;
    }
    
    &::after {
      @include s-m {
        content: '';
        position: absolute;
        bottom: 2.5vw;
        right: -4.29vw;
        width: 5.71vw;
        height: 3.21vw;
        background-image: url('~@/assets/images/in-app-network/arrow-curved.svg');
        background-repeat: no-repeat;
        background-size: 100% auto;
      }

      @include s-xxl {
        bottom: 35px;
        right: -60px;
        width: 80px;
        height: 45px;        
      }
    }
  }  
}

.InAppNetworkAbout {
  position: relative;
  overflow: hidden;
  padding-bottom: 20vw;
  background-size: 100% auto;
  background-repeat: no-repeat;
  z-index: 1;

  @include s-m {
    margin-top: -12.86vw;
    background-image: url('~@/assets/images/in-app-network/bg-about.svg');
  }

  @include s-xxl {
    margin-top: -180px;
    padding-bottom: 190px;
  }
  
  @include s-max-m {
    background-image: linear-gradient(0deg, #301B4B -7.53%, #52006B 61.63%);

    &:before {
      position: absolute;
      z-index: 3;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      border-left: 50vw solid transparent;
      border-right: 50vw solid transparent;
      border-top: 15.21vw solid var(--color-body1);
  
      @include s-xxl {
        border-width: 213px 700px 0;
      }
    } 
    
    &:after {
      position: absolute;
      z-index: 3;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      border-left: 50vw solid transparent;
      border-right: 50vw solid transparent;
      border-bottom: 15.21vw solid var(--color-body1);
  
      @include s-xxl {
        border-width: 0 700px 213px;
      }
    }  
  }

  &-In {
    position: relative;
    display: flex;
    padding-right: 4.29vw;
    padding-left: 4.29vw;

    @include s-xxl {
      padding-right: 4.29vw;
      padding-left: 4.29vw;
    }

    @include s-xxl {
      padding-right: 60px;
      padding-left: 60px;
    }
  }

  &-Text {
    padding-top: 17.58vw;
    color: var(--color-text-main);
    font-size: 20px;

    @include s-m {
      width: 50%;
      flex: 0 0 auto;
      padding-left: 8.57vw;
      padding-top: 17.14vw;
      padding-right: 4.86vw;
      font-size: 1.43vw;
    }

    @include s-xxl {
      padding-left: 120px;
      padding-top: 240px;
      padding-right: 68px;      
      font-size: 20px;
    }

    p {
      margin-bottom: 1em;
    }
  }

  &-TextText {
    position: relative;
    z-index: 10;
    width: 90%;
    margin-bottom: 65px;

    @include s-m {
      width: auto;
      margin-bottom: 0;
    }
  }

  &-TextFirst {
    font-weight: 500;
    max-width: 313px;

    @include s-max-s {
      max-width: 73.13vw;
    }
  }

  &-TextSecond {
    color: var(--color-text-violet-light);
    width: 200px;

    @include s-max-s {
      font-size: 22px;
    }
  }

  &-TextMarked {
    color: var(--color-text-green);
  }

  &-Benefits {
    position: relative;
    z-index: 1;
  }

  &-BenefitsItem {
    margin-bottom: 18px;

    @include s-m {
      margin-bottom: 5px;
    }
  }

  &-BenefitsItemBtn {
    display: flex;
    align-items: center;
    padding-right: 32px;
    padding-left: 80px;
    padding-top: 14px;
    padding-bottom: 14px;
    border-radius: 58px;
    background-color: var(--color-text-green);
    background-image: url('~@/assets/images/in-app-network/arrow-curve.svg');
    background-size: 30px auto;
    background-repeat: no-repeat;
    background-position: 32px 50%;
    cursor: default;
    color: var(--color-text-main);
    font-size: 20px;
    font-weight: 700;

    @include s-m {
      display: inline-flex;
      background-size: 2vw auto;
      background-position: 1.86vw 50%;
      padding: 0.71vw 1.86vw 0.71vw 4.86vw;
      font-size: 1.21vw;
    }

    @include s-xxl {
      padding: 10px 26px 10px 68px;
      background-size: 28px auto;
      background-position: 26px 50%;
      font-size: 17px;
    }
  }

  &-Picture {
    position: absolute;
    top: 9.11%;
    right: -47px;
    z-index: 1;
    max-width: 50%;
  
    @include s-max-s {
      right: -28.04vw;
      max-width: 366px;
    }

    @media (max-width: 400px) {
      right: -48vw;
    }

    @include s-m {
      position: static;
      width: 50%;
      max-width: 100%;
      flex: 0 0 auto;
      padding-left: 4.86vw;
    }

    @include s-xxl {
      padding-left: 68px;
    }
  } 
}
</style>
